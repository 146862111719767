import React from "react";
import { Link } from "react-router-dom";

function MobileCloseButton(props) {
  return (
    <Link
      to="/"
      className="round-button"
      style={{
        position: "fixed",
        right: "var(--margin)",
        top: "var(--margin)",
        width: 30,
        height: 30,
        borderRadius: "var(--button-height)",
        transition: "background-color 0.25s ease, color 0.5s ease",
        zIndex: 7,
        backgroundColor: "white",
      }}
    >
      <img src="images/icons/x.svg" style={{ width: "16" }} alt="Close" />
    </Link>
  );
}

export default MobileCloseButton;
