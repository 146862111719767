import { motion } from "framer-motion";

export default function Logo(props) {
  const { size } = props;
  const strokeWidth = 3;

  const trans = {
    duration: 1,
    ease: "easeInOut",
  };

  return (
    <div style={{ width: size, height: size }}>
      <motion.svg viewBox="0 0 115 115" xmlns="http://www.w3.org/2000/svg">
        <motion.path
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={trans}
          strokeWidth={strokeWidth}
          strokeDasharray="0 1"
          fill="none"
          stroke="black"
          strokeMiterlimit="1.5"
          strokeLinecap="square"
          d="M62.8977 0L5.5 57.3977L61 112.898"
        />
        <motion.path
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{ pathLength: 1, opacity: 1 }}
          transition={trans}
          strokeWidth={strokeWidth}
          strokeDasharray="0 1"
          fill="none"
          stroke="black"
          strokeMiterlimit="1.5"
          strokeLinecap="square"
          d="M93.5 112.898L38 57.3977L92.8977 2.5"
        />
        <motion.path
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={trans}
          strokeWidth={strokeWidth}
          strokeDasharray="0 1"
          fill="none"
          stroke="black"
          strokeMiterlimit="1.5"
          strokeLinecap="square"
          d="M1.5 113.193L1.593 1H60.0196"
        />
      </motion.svg>
    </div>
  );
}
