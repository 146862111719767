import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import amplitude from "amplitude-js";

import About from "./about";

import { Nudge } from "./projects/nudge";
import { Salveo } from "./projects/salveo";
import { Apps } from "./projects/apps";
import { Gibson } from "./projects/gibson";
import { Web } from "./projects/web";

import { BrowserRouter, Routes, Route } from "react-router-dom";

amplitude.getInstance().init("0cce7ed79a06b6dc107fb9bb67c4635d");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="about" element={<About />} />
          <Route path="apps" element={<Apps />} />
          <Route path="gibson" element={<Gibson />} />
          <Route path="nudge" element={<Nudge />} />
          <Route path="salveo" element={<Salveo />} />
          <Route path="web" element={<Web />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
