import React from "react";
import useDeviceInfo from "./useDeviceInfo";

function About(props) {
  const { isMobile } = useDeviceInfo();
  return (
    <div
      class="mobile-margin"
      style={{
        height: !isMobile ? "100vh" : "auto",
        paddingTop: isMobile ? 75 : 0,
        paddingBottom: isMobile ? 75 : 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <r-grid
        columns="6"
        class="mobile-margin"
        style={{
          marginTop: "var(--margin)",
          marginBottom: "var(--margin)",
        }}
      >
        <r-cell span-s="3">
          <img
            src="images/portrait.jpg?ver=2"
            style={{
              marginBottom: isMobile ? "var(--margin)" : 0,
              borderRadius: isMobile ? 200 : 0,
            }}
            alt="Picture of Russ Campbell"
          />
        </r-cell>
        <r-cell span="4" span-s="6">
          <div style={{ marginBottom: isMobile ? "var(--margin)" : 0 }}>
            <strong>Russ Campbell / Product Designer</strong>
          </div>
          "Design is good to the degree that it makes things better."
          <br />
          <br />
          In 2003, one year before graduating with a traditional degree in
          Graphic Design, I took over the senior interative design role at the
          agency I was interning at. For the next 18 years I continued to
          exercise my creative energy in various lead design roles through which
          I cultivated a multi-disciplinary approach. I've designed apps,
          websites, logos, design systems, reports, graphs, games, characters,
          illustrations and animations.
          <br />
          <br />I sincerely believe that if you can design anything you can
          design everything. The most important thing in design is having a
          sense of quality. If you are a chef you must have an appreciation for
          taste. If you are a musician you must have an ear for harmony. To
          design well you must have an appreciation for quality.
          <br />
          <br />
          <a href="images/resume.pdf" target="_blank">
            Resume
          </a>
        </r-cell>
      </r-grid>
    </div>
  );
}

export default About;
