import React from "react";
import ProjectFeature from "./_common/ProjectFeature";
import projects from "./";

function Web(props) {
  const project = projects[4];
  return (
    <div>
      <ProjectFeature {...project} />
    </div>
  );
}

export { Web };
