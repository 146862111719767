import React, { useState } from "react";
import useDeviceInfo from "../../useDeviceInfo";
import imageSizes from "../../imageSizes";
import { motion, AnimatePresence } from "framer-motion";
import Portal from "./Portal";
import amplitude from "amplitude-js";

function Image(props) {
  const { span, url, hasMobile, link, videoClicked } = props;
  const { isMobile } = useDeviceInfo();
  const [isHovered, setIsHovered] = useState(isMobile);
  const linkExtension = link ? link.substr(link.length - 3, link.length) : null;

  return (
    <r-cell
      span={span}
      style={{ position: "relative", cursor: link ? "pointer" : "default" }}
      onMouseEnter={
        link && !isMobile
          ? () => {
              setIsHovered(true);
            }
          : null
      }
      onMouseLeave={
        link && !isMobile
          ? () => {
              setIsHovered(false);
            }
          : null
      }
      onClick={
        link
          ? () => {
              if (linkExtension !== "mp4") {
                window.open(link);
              } else {
                videoClicked(link, linkExtension);
              }
              amplitude.getInstance().logEvent("Image Clicked", {
                link: link,
                type: linkExtension,
              });
            }
          : null
      }
    >
      <AnimatePresence>
        {link && (isHovered || isMobile) && (
          <motion.div
            initial={!isMobile ? { opacity: 0 } : false}
            animate={!isMobile ? { opacity: 1 } : {}}
            exit={!isMobile ? { opacity: 0 } : {}}
            transition={{ duration: 0.3 }}
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,.2)",
              width: "100%",
              height: "100%",
              zIndex: 1,
              borderRadius: 4,
            }}
          >
            <motion.div
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              style={{
                display: "inline-flex",
                alignItems: "center",
                borderRadius: 25,
                backgroundColor: "white",
                height: "var(--button-height)",
                boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px 0px",
                padding: "0px 1vw",
              }}
            >
              <img
                src={
                  {
                    mp4: "images/icons/play-circle.svg",
                    pdf: "images/icons/file-text.svg",
                  }[linkExtension] || "images/icons/eye.svg"
                }
                alt="Play Video"
                style={{ height: "1.35vw", marginRight: ".5vw", minHeight: 14 }}
              />
              {{
                mp4: "Watch",
                pdf: "PDF",
              }[linkExtension] || "View"}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <picture>
        {imageSizes[span].map((w) => (
          <source
            media={`(max-width: ${w}px)`}
            srcset={
              "images/" +
              url.substr(0, url.length - 4) +
              (hasMobile && isMobile ? "-mobile-" : "-") +
              w +
              url.substr(url.length - 4, url.length)
            }
          />
        ))}

        <img
          src={
            "images/" +
            url.substr(0, url.length - 4) +
            "-" +
            imageSizes[span][imageSizes[span].length - 1] +
            url.substr(url.length - 4, url.length)
          }
          style={{ width: "100%", borderRadius: 4, margin: 0 }}
        />
      </picture>
    </r-cell>
  );
}

function Images(props) {
  const { images, span, hasMobile, link, links, minHeight } = props;

  const defaultSpan = images.length === 1 ? 6 : images.length === 2 ? 3 : 2;
  const [showVideo, setShowVideo] = useState(null);

  return (
    <r-grid
      columns="6"
      class="mobile-margin"
      style={{
        marginTop: "var(--margin)",
        marginBottom: "var(--margin)",
        minHeight: minHeight,
      }}
    >
      {showVideo && (
        <Portal>
          <div
            onClick={() => {
              setShowVideo(null);
            }}
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,.35)",
              zIndex: 9,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <video
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{
                objectFit: "contain",
                width: "100%",
                maxWidth: 1024,
                zIndex: 99,
              }}
              controls
              autoplay
            >
              <source src={showVideo} type="video/mp4" />
            </video>
          </div>
        </Portal>
      )}
      {images.map((url, i) => (
        <Image
          url={url}
          span={span && span[i] ? span[i] : defaultSpan}
          hasMobile={hasMobile}
          link={link ? link : links && i < links.length ? links[i] : null}
          videoClicked={(link, videoExtension) => {
            setShowVideo(link);
          }}
          minHeight={minHeight}
        />
      ))}
    </r-grid>
  );
}

export default Images;
