import React from "react";

function TextBlock(props) {
  const { children } = props;
  return (
    <r-grid
      columns="6"
      class="mobile-margin"
      style={{
        marginTop: "var(--margin)",
        marginBottom: "var(--margin)",
      }}
    >
      <r-cell span="1-6">{children}</r-cell>
    </r-grid>
  );
}

export default TextBlock;
