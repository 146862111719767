import React from "react";
import {
  ProjectFeature,
  TextBlock,
  Images,
  Section,
  MinHeights,
} from "./_common/";
import projects from "./";

function Nudge(props) {
  const project = projects[0];
  return (
    <div>
      <Section id="Intro" showTitle={false}>
        <ProjectFeature {...project} />
        <TextBlock>
          For the last 8 years I've been the product lead for Nudge, seeing our
          company through many different pivots and iterations. Throughout that
          time it has been my role to shepherd a vision for our product that
          best reflects the goals of our stakeholders and customers. My process
          always starts with listening, then mocking up and presenting, and
          finally seeing the concept through the development process. Due to our
          small size I often find myself jumping into development to help things
          come together.
        </TextBlock>
        <Images
          images={["nudge-welcome.png", "nudge-feed.png", "nudge-my-data.png"]}
          minHeight={MinHeights.PHONE11}
        />
        <TextBlock>
          Today Nudge is a platform that empowers coaches, consultants,
          influencers, and organizations that want to launch an online coaching
          program with the ability to create their own app without hiring
          developers or spending tons of money. This is a far stretch from where
          we started; the "klout for healthy living" -{" "}
          <a
            href="https://techcrunch.com/2014/08/17/nudge-is-a-dashboard-for-all-your-fitness-wearables-and-apps/"
            rel="noreferrer"
            target="_blank"
          >
            Tech Crunch
          </a>
        </TextBlock>
        <Images
          images={["nudge-graph.png", "nudge-syncing.png"]}
          span={["2", "4"]}
          minHeight={MinHeights.PHONE11}
          hasMobile
        />
      </Section>
      <Section id="Program Update">
        <TextBlock>
          While some core elements of the original vision remain, such as the
          ability to sync with most any health tracking device, the current
          vision of the product is radically different. Ever since we shifted to
          target coaching professionals around 2013 we had received steady
          feedback that the habit tracking side of our product fell short in one
          major way. It wasn't dynamic enough. It only let you create a static
          list of trackers that the client was presented with everyday. We found
          through our research that some things should be tracked less
          frequently than others, real coaching programs often include a
          different focus week-to-week, and ultimately seeing the same thing
          everyday just wasn't all that engaging.
        </TextBlock>
        <Images
          images={["nudge-cards-feature.png", "nudge-card-movement.png"]}
          span={["4", "2"]}
          minHeight={MinHeights.PHONE11}
        />
        <TextBlock>
          From this feedback ultimately the card concept was born. Cards would
          be small bite-sized elements of a program rich with images and text
          that could live in a feed. The feed would provide a means to always
          push something fresh into the client's experience while also creating
          a way to scroll back and see what you had done in the past. I
          presented the earliest form of this concept back in 2017 but
          ultimately we didn't get the opportunity to execute on it until 2020.
          I'm happy to say the shift was ultimately a great success and has has
          been key to our growth in the last year.
        </TextBlock>
        <Images
          images={[
            "nudge-card-mindfulness.png",
            "nudge-card-hunger.png",
            "nudge-community.png",
          ]}
          minHeight={MinHeights.PHONE11}
        />
      </Section>
      <Section id="Planning & Presenting">
        <TextBlock>
          One of the biggest challenges to this update was the migration. I had
          to think through how a single customizable screen could translate into
          a feed of cards. While we did our best to communicate the changes
          prior to launch I still had to ensure the translation would make sense
          to both our customer and their clients even if they didn't see it
          coming. This migration guide I put together was key in making it
          easier for our sales people to communicate the changes and our
          customers to prepare.
        </TextBlock>
        <Images
          images={["nudge-migration-guide.png"]}
          link={"images/nudge-migration-guide.pdf"}
          minHeight={MinHeights.WIDE}
        />
        <TextBlock>
          At Nudge it has generally been my responsibility to communicate our
          design direction and long-term product vision. Pre-covid we had yearly
          retreats where I would make presentations highlighting our focus as a
          product team for the next year. in 2018 I gave a presentation where I
          first unveiled the card concept. At the time it was simply a way to
          solve the problem of dynamic programs. It is also where I honed in our
          key design priniciples.
        </TextBlock>
        <Images
          images={["nudge-original-card.png", "nudge-design-principles.png"]}
          link={"images/programs-presentation.pdf"}
          minHeight={MinHeights.TWO_FOUR_THREE}
        />
        <Images
          images={["nudge-programs-profile.png"]}
          minHeight={MinHeights.WIDE}
          hasMobile
        />
      </Section>
      <Section id="Prototyping">
        <TextBlock>
          The ideas that I'm most interested in developing are generally too
          complex and experiential in nature for static mockups. I'm far more
          interested in how something works than how it looks anyway.
          Prototyping is one of my absolute favorite things to do. Here is just
          one example where I was experimenting with an early version of the
          card designer. It was in this stage that the "design your own app"
          concept was really taking flight.
        </TextBlock>
        <Images
          images={["nudge-drag-drop-prototype.png"]}
          link={"images/program-designer.mp4"}
          minHeight={MinHeights.WIDE}
        />
        <Images
          images={["nudge-v4-prototype.png", "nudge-v4-builder.png"]}
          links={["images/new-tracker-prototype.mp4"]}
          minHeight={MinHeights.TWO_FOUR_THREE}
        />
      </Section>
      <Section id="V4 Update">
        <TextBlock>
          V4, the 4th major version of Nudge, was a massive overhaul of our
          client app. On the one hand it was a move from Cordova to React
          Native, but more importantly it was where we formalized our commitment
          to the importance of personal connection in successful coaching. At a
          time where most competitors were looking to AIs and algorithms, we
          proudly asserted our dependence on real human coaches and their
          creativity. During this time I would often say "our coaches already
          know how to motivate their clients, our job is to amplify their
          voice."
        </TextBlock>
        <Images
          images={["nudge-v4-feature.png", "nudge-v4-welcome.png"]}
          span={["4", "2"]}
          links={["images/nudge-v4-guide.pdf"]}
          minHeight={MinHeights.TWO_FOUR_THREE}
        />

        <TextBlock>
          Focusing on our customers ability to "shape the motivational
          environment" was a natural move for our product. Through regularly
          meeting with our customers as part of our white-labeling process I
          came to see them as fellow creatives. In light of this it became
          paramount that our design language be as neutral and modern as
          possible so that their voice and personality could be communicated
          most clearly.
        </TextBlock>
        <Images
          images={[
            "nudge-v4-log-1.png",
            "nudge-v4-log-2.png",
            "nudge-v4-log-3.png",
          ]}
          minHeight={MinHeights.PHONE8}
        />
        <TextBlock>
          In version 4 of Nudge the ability to create a custom tracker became
          central to our value proposition. This greatly broadened our appeal as
          customers who couldn't find a coaching app that fit their program
          could create one using ours. Minimizing choice and maximizing utility
          was one of the core values of our design at this time. Small choices,
          such as what color a tracker should be, could have significant impact
          on the feel of the app as there wasn't much to compete with it.
        </TextBlock>
        <Images
          images={[
            "nudge-v4-trends-1.png",
            "nudge-v4-trends-2.png",
            "nudge-v4-leaderboard.png",
          ]}
          minHeight={MinHeights.PHONE8}
        />
      </Section>
      <Section id="Promotional Videos">
        <TextBlock>
          I wear many, many hats at Nudge. I suppose this is typical of a start
          up but I can sincerely say that it's drawn from every nook and cranny
          of my past experience. A great example of this is some of the
          promotional videos I created in After Effects.
        </TextBlock>
        <Images
          images={[
            "nudge-fitness-video-1.png",
            "nudge-fitness-video-2.png",
            "nudge-fitness-video-3.png",
          ]}
          links={[
            "images/nudge-fitness-video-1.mp4",
            "images/nudge-fitness-video-1.mp4",
            "images/nudge-fitness-video-3.mp4",
          ]}
          minHeight={MinHeights.THREE_SMALL}
        />
        <TextBlock>
          We used the following video to promote the launch of Nudge Coach, our
          first major pivot into the coaching market. This video really had legs
          and was modified and reused in many marketing campaigns and investor
          presentations.
        </TextBlock>
        <Images
          images={["nudge-coach-video.png"]}
          link={"images/nudge-coach-video.mp4"}
          minHeight={MinHeights.WIDE}
        />
      </Section>
    </div>
  );
}

export { Nudge };
