import React from "react";
import { Link } from "react-router-dom";
import amplitude from "amplitude-js";

function Links(props) {
  return (
    <>
      <Link
        to="/about"
        onClick={() => {
          amplitude.getInstance().logEvent("Page Clicked", {
            title: "About",
          });
        }}
      >
        About
      </Link>
      <Link
        to="http://www.dribbble.com/russcampbell"
        target="_blank"
        onClick={() => {
          amplitude.getInstance().logEvent("Link Clicked", {
            title: "Dribbble",
          });
        }}
      >
        Dribbble
      </Link>
      <Link
        to="https://www.linkedin.com/in/russ-campbell-4231572/"
        target="_blank"
        onClick={() => {
          amplitude.getInstance().logEvent("Link Clicked", {
            title: "Linkedin",
          });
        }}
      >
        Linkedin
      </Link>
      <Link
        to="https://twitter.com/russcampbell"
        target="_blank"
        onClick={() => {
          amplitude.getInstance().logEvent("Page Clicked", {
            title: "Twitter",
          });
        }}
      >
        Twitter
      </Link>
    </>
  );
}

export default Links;
