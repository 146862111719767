import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Links from "./Links";
import Logo from "./Logo";

function Nav(props) {
  const [isHome, setIsHome] = useState(true);

  let location = useLocation();

  useEffect(() => {
    setIsHome(location.pathname === "/");
  }, [location]);

  return (
    <r-cell span={"7-8"} id="nav" class={`${isHome ? "home" : ""}`}>
      <div id="nav-inner">
        <div>
          <div style={{ paddingTop: "0.5vw", marginBottom: "1vw" }}>
            <Logo size="7vw" />
          </div>
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.8,
              delay: 0.75,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            Russ Campbell
          </motion.div>
        </div>
        <motion.div
          className="txt-gray"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.8,
            delay: 1,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          Staff Designer @ Etsy
          <br />
          Design Systems
        </motion.div>
        <motion.div
          id="nav-footer"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.8,
            delay: 1.25,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <Links />
        </motion.div>
        <hr />
      </div>
    </r-cell>
  );
}

export default Nav;
