import React from "react";
import { Link } from "react-router-dom";

function BackButton(props) {
  return (
    <Link
      to="/"
      onClick={() => {
        // hideNav();
      }}
      className="round-button margin-left-column"
      style={{
        position: "fixed",
        left: "2.5vw",
        top: "4.5vw",
        width: "var(--button-height)",
        height: "var(--button-height)",
        borderRadius: "var(--button-height)",
        transition: "background-color 0.25s ease, color 0.5s ease",
        zIndex: 7,
      }}
    >
      <img
        src="images/icons/arrow-left.svg"
        style={{ width: "1.75vw" }}
        alt="Back"
      />
    </Link>
  );
}

export default BackButton;
