const projects = [
  {
    title: "Nudge",
    headline: "Leading A/New Vision/For Online/Coaching.",
    job: "Product Owner",
    dates: "2013-Present",
    skills: "Product Owner,UX Design,React, Branding, Motion",
    image: "nudge-feature",
    bg: "blue",
    sections: [
      { title: "Programs", position: 0 },
      { title: "Programs Prototype", position: 200 },
    ],
  },
  {
    title: "Salveo",
    headline: "Promoting/Empathy &/Innovation in/Healthcare.",
    job: "Design Director",
    dates: "2019 - 2020",
    skills: "Design Director,UX Design,Branding,React",
    image: "salveo-feature",
    bg: "yellow",
  },
  {
    title: "Apps",
    headline: "Solving Big/Problems/On Small/Screens.",
    job: "Designer & Developer",
    dates: "2019 - 2020",
    skills: "UI Design, UX Design, Swift, Obj-C",
    image: "apps-feature",
    bg: "purple",
  },
  {
    title: "Gibson",
    headline: "Designing/For Legends/of Rock/& Roll.",
    job: "Senior Designer",
    dates: "2019 - 2020",
    skills: "Art Direction,Branding, Frontend Dev",
    image: "gibson-feature",
    bg: "black",
  },
  // {
  //   title: "Web",
  //   headline: "A Brief/Guide To/18 Years/of Web.",
  //   job: "Senior Designer",
  //   dates: "2019 - 2020",
  //   skills: "Product Owner,UX Design,React",
  //   image: "web-feature",
  //   bg: "black",
  // },
];

export default projects;
