import React from "react";

function Section(props) {
  const { children, id, showTitle } = props;
  return (
    <section
      id={id}
      style={{
        paddingTop: showTitle ? "var(--margin)" : 0,
      }}
    >
      {showTitle && (
        <h2 class="mobile-margin" style={{ textTransform: "capitalize" }}>
          {id}
        </h2>
      )}
      {children}
    </section>
  );
}

Section.defaultProps = {
  showTitle: true,
};

export default Section;
