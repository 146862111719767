import { Outlet, useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import "./App.css";
import projects from "./projects/index";
import PageSections from "./PageSections";
import { ProjectFeatureSummary } from "./projects/_common";
import BackButton from "./BackButton";
import MobileCloseButton from "./MobileCloseButton";
import Nav from "./Nav";
import Links from "./Links";
import useDeviceInfo from "./useDeviceInfo";

function App(props) {
  const pageContent = useRef(null);
  const [isHome, setIsHome] = useState(true);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const { isMobile } = useDeviceInfo();

  let location = useLocation();

  useEffect(() => {
    setIsHome(location.pathname === "/");
  }, [location]);

  return isHome ? (
    // Home page
    <r-grid columns="8" style={{ height: "100%" }}>
      {isMobile && (
        <r-cell id="mobile-header" span={"1-8"}>
          <img
            src="images/logo.png"
            width="18"
            alt="Russ Campbell Design Logo"
          />
          Russ Campbell
        </r-cell>
      )}
      <r-cell
        id="featured-projects"
        span={"1-6"}
        span-s="1-8"
        ref={pageContent}
      >
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            duration: 0.8,
            delay: 0.25,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          {projects.map((p, i) => (
            <ProjectFeatureSummary
              highlighted={highlightedIndex === i}
              onMouseOver={() => {
                setHighlightedIndex(i);
              }}
              {...p}
            />
          ))}
          {isMobile && (
            <div id="mobile-footer">
              <Links />
            </div>
          )}
        </motion.div>
      </r-cell>
      <Nav></Nav>
    </r-grid>
  ) : (
    // Project page
    <r-grid
      columns={isMobile ? "6" : "7"}
      class="page-content bg-light-gray"
      ref={pageContent}
    >
      {!isMobile && (
        // Project sidebar
        <r-cell span={"1-1"}>
          <BackButton />
          <PageSections
            scrollElement={pageContent}
            onClickSection={(s) => {
              pageContent.current.scrollTo({
                top: s.start,
                left: 0,
                behavior: "smooth",
              });
            }}
          />
        </r-cell>
      )}
      {isMobile && <MobileCloseButton />}
      <r-cell span={isMobile ? "1-7" : "2-6"}>
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            duration: 0.8,
            delay: 0.25,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <Outlet />
        </motion.div>
      </r-cell>
    </r-grid>
  );
}

export default App;
