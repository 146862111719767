import useWindowDimensions from "./windowDimensions";

function useDeviceInfo(props) {
  const windowDimensions = useWindowDimensions();

  return {
    isMobile: windowDimensions.width < 600,
  };
}

export default useDeviceInfo;
