import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import amplitude from "amplitude-js";

function SectionBullet(props) {
  const { section, active } = props;
  const dotSize = ".6vw";
  const [hovered, setHovered] = useState(false);
  const textObj = useRef(null);

  const textWidth =
    textObj && textObj.current ? textObj.current.offsetWidth : 0;

  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        padding: "0 1vw",
        height: "var(--button-height)",
        backgroundColor: hovered ? "white" : "",
        boxShadow: hovered ? "0px 1px 2px 0px rgba(0, 0, 0, 0.25)" : undefined,
        transition: "background-color 0.25s ease, color 0.5s ease",
        borderRadius: "5vw",
        marginLeft: "-1.4vw",
        cursor: "pointer",
      }}
      onMouseEnter={(e) => {
        setHovered(true);
      }}
      onMouseLeave={(e) => {
        setHovered(false);
      }}
    >
      <div
        style={{
          border: ".15vw solid black",
          backgroundColor: active ? "black" : "",
          width: dotSize,
          height: dotSize,
          borderRadius: dotSize,
        }}
      ></div>
      <motion.div
        animate={{
          maxWidth: hovered ? textWidth : 0,
        }}
        style={{
          textTransform: "capitalize",
          marginLeft: "1vw",
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxWidth: 0,
        }}
      >
        <span ref={textObj} style={{ display: "inline-block" }}>
          {section.id}
        </span>
      </motion.div>
    </div>
  );
}

function PageSections(props) {
  const { scrollElement, onClickSection, debug } = props;
  const [sections, setSections] = useState([]);
  const [position, setPosition] = useState(0);

  const elementScrolled = (e) => {
    setPosition(e.target.scrollTop);
  };

  scrollElement.current.onscroll = elementScrolled;

  useEffect(() => {
    const updateSections = () => {
      setTimeout(() => {
        const idArray = [].slice
          .call(scrollElement.current.getElementsByTagName("Section"))
          .map((s) => ({
            id: s.id,
            start: s.offsetTop,
            end: s.offsetTop + s.offsetHeight,
          }));
        setSections(idArray);
      }, 750);
    };

    setTimeout(updateSections, 100);

    window.addEventListener("resize", updateSections);
    //scrollElement.current.addEventListener("onscroll", elementScrolled);

    return () => {
      window.removeEventListener("resize", updateSections);
      //scrollElement.current.removeEventListener("onscroll", elementScrolled);
    };
  }, [scrollElement]);

  let location = useLocation();
  if (location.pathname)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "fixed",
          left: "6.5vw",
          top: "10vw",
          bottom: "10vw",
          zIndex: 9,
        }}
      >
        {debug && <div>{position}</div>}

        <div style={{ position: "relative" }}>
          {sections && sections.length > 1 && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              {sections.map((s, i) => (
                <div
                  onClick={() => {
                    onClickSection(s);
                    amplitude.getInstance().logEvent("Section Clicked", {
                      title: s.id,
                    });
                  }}
                  style={{ marginTop: i > 0 ? "1vw" : 0 }}
                >
                  <SectionBullet
                    section={s}
                    active={
                      (i === 0 && position < sections[1].start) ||
                      (position >= sections[i].start &&
                        position < sections[i].end)
                    }
                  />
                </div>
              ))}
            </motion.div>
          )}
        </div>
      </div>
    );
}

export default PageSections;
