import React, { useState } from "react";
import { motion } from "framer-motion";
import useWindowDimensions from "../../windowDimensions";
import imgSizes from "../../imageSizes";

function ProjectFeature(props) {
  const { title, headline, bg, image } = props;

  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < 600;
  const bgClass = bg ? bg : "black";
  const headlineParts = headline.split("/");
  const imageSizes = imgSizes[4];
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div
      class={"animate-bg-" + bgClass + " show"}
      style={{
        padding: "var(--margin)",
        overflow: "hidden",
        position: "relative",
        zIndex: 5,
        height: `calc(${
          isMobile ? "56.25vw" : "41.355vw"
        } - (var(--margin)*2))`, // 16:9 (Desktop isn't full window width)
      }}
    >
      <r-grid columns="7" class="">
        <r-cell
          span="row"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>{title}</div>
        </r-cell>
        <r-cell span="row">&nbsp;</r-cell>
        <r-cell span="2">
          <h2>
            {headlineParts.map((h) => (
              <div style={{ whiteSpace: "nowrap" }}>{h}</div>
            ))}
          </h2>
          <div
            style={{
              position: "absolute",
              bottom: "var(--margin)",
            }}
          ></div>
        </r-cell>
        <r-cell
          span="2"
          span-s="3"
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: -1,
              right: 0,
            }}
          >
            <picture>
              {imageSizes.map((w) => (
                <source
                  media={`(max-width: ${w}px)`}
                  srcset={
                    "images/" +
                    image +
                    (isMobile ? "-mobile-" : "-") +
                    w +
                    ".png"
                  }
                />
              ))}

              <motion.img
                onLoad={() => {
                  setImageLoaded(true);
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: imageLoaded ? 1 : 0 }}
                src={
                  "images/" +
                  image +
                  "-" +
                  imageSizes[imageSizes.length - 1] +
                  ".png"
                }
                style={{ width: isMobile ? "66vw" : "51.105vw", margin: 0 }} // Adjust desktop because it's not full width. 51.105 is effectively the same as 66vw once you adjust for the margin.
              />
            </picture>
          </div>
        </r-cell>
      </r-grid>
    </div>
  );
}

export default ProjectFeature;
