import React from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import useWindowDimensions from "../../windowDimensions";
import imgSizes from "../../imageSizes";
import amplitude from "amplitude-js";

function ProjectFeatureSummary(props) {
  const {
    title,
    headline,
    skills,
    bg,
    centerImage,
    noMobileSpacer,
    image,
    highlighted,
    onMouseOver,
  } = props;

  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < 600;
  const showHoverState = highlighted && !isMobile;
  const bgClass = bg ? bg : "black";
  const hoverClass = showHoverState ? " show" : "";

  const navigate = useNavigate();
  const headlineParts = headline.split("/");

  const imageSizes = isMobile ? imgSizes[4] : imgSizes[3];

  return (
    <r-cell
      span="6"
      onMouseOver={onMouseOver}
      onClick={() => {
        navigate("/" + title.toLowerCase());
        amplitude.getInstance().logEvent("Feature Clicked", { title: title });
      }}
      class={"animate-bg-" + bgClass + "hoverClass"}
      style={{ cursor: "pointer" }}
    >
      <motion.div
        class={"animate-bg-" + bgClass + hoverClass}
        style={{
          padding: "var(--margin)",
          overflow: "hidden",
          position: "relative",
          zIndex: 5,
          height: isMobile ? "calc(56.25vw - (var(--margin)*2))" : "20vw", //mobile is 16:9
          borderBottom: isMobile ? "4px solid white" : undefined,
        }}
      >
        <r-grid columns="7">
          <r-cell span="2">
            {title}
            <h2>
              {headlineParts.map((h) => (
                <div style={{ whiteSpace: "nowrap" }}>{h}</div>
              ))}
            </h2>
          </r-cell>
          {isMobile && !noMobileSpacer && <r-cell span-s="1">&nbsp;</r-cell>}
          <r-cell
            span="2"
            span-s="3"
            style={{
              display: "flex",
              justifyContent: centerImage ? "center" : "flex-start",
            }}
          >
            {isMobile ? (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  height: "100%",
                }}
              >
                <picture>
                  {imageSizes.map((w) => (
                    <source
                      media={`(max-width: ${w}px)`}
                      srcset={"images/" + image + "-mobile-" + w + ".png"}
                    />
                  ))}

                  <img
                    src={
                      "images/" +
                      image +
                      "-mobile-" +
                      imageSizes[imageSizes.length - 1] +
                      ".png"
                    }
                    style={{ width: "66vw", borderRadius: 4, margin: 0 }}
                    alt={title}
                  />
                </picture>
              </div>
            ) : (
              <AnimatePresence>
                {showHoverState && (
                  <motion.div
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 20, opacity: 0 }}
                    style={{
                      position: "absolute",
                      top: 0,
                    }}
                  >
                    <picture>
                      {imageSizes.map((w) => (
                        <source
                          media={`(max-width: ${w}px)`}
                          srcset={"images/" + image + "-summary-" + w + ".png"}
                        />
                      ))}

                      <img
                        src={
                          "images/" +
                          image +
                          "-summary-" +
                          imageSizes[imageSizes.length - 1] +
                          ".png"
                        }
                        style={{ height: "30vw", borderRadius: 4, margin: 0 }}
                      />
                    </picture>
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </r-cell>
          {!isMobile && (
            <r-cell span="3" style={{ position: "relative", zIndex: 1 }}>
              <div style={{ textAlign: "right" }}>
                {skills &&
                  skills.split(",").map(function (s) {
                    return <div>{s}</div>;
                  })}
              </div>
            </r-cell>
          )}
        </r-grid>
      </motion.div>
    </r-cell>
  );
}

export default ProjectFeatureSummary;
