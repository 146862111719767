import React from "react";
import { ProjectFeature, TextBlock, Images, Section } from "./_common/";
import projects from "./";

function Gibson(props) {
  const project = projects[3];
  return (
    <div>
      <Section id="Intro" showTitle={false}>
        <ProjectFeature {...project} />
        <TextBlock>
          A Gibson J45 was the first guitar I ever owned. It was my mother's
          guitar that she received from my grandfather who exclusively played
          Gibson instruments. I always had a certain connection with the brand.
          It felt like it was part of my family's story. While working at Gibson
          I felt like I was part of a much longer thread with a rich history.
        </TextBlock>
        <Images
          images={["gibson-holiday-1.png", "gibson-holiday-2.png"]}
          links={["images/gibson-holiday.mp4"]}
        />
        <TextBlock>
          I think my title was Senior Interactive Designer but due to high
          turnover in leadership I ended up with much more agency than my title
          warranted. I came into the position without receiving much direction
          because who I would typically report to had been recently let go.
          Rather than just sitting at my desk, I launched into a self initiated
          redesign of all our websites. The interim head of the web team took
          interest in my work and submitted it up to the CEO for approval. He
          loved it.
        </TextBlock>
        <Images images={["gibson-holiday-3.png", "gibson-holiday-4.png"]} />
        <TextBlock>
          My objectives were pretty clear to me. I had my own personal passion
          for our product and I knew what I would want to see. You can't
          purchase a guitar from Gibson.com. People didn't come there to buy
          things. You couldn't. They came to experience art, to cultivate their
          passion, and to be inspired. They came because they couldn't make it
          to the guitar shop on their lunch break. I knew my job was to make the
          guitar the central focus.
        </TextBlock>
        <Images
          images={["gibson-usa-1.png", "gibson-usa-2.png"]}
          links={["images/gibson-usa.mp4"]}
          span={["2", "4"]}
        />
        <TextBlock>
          Practically this meant making the body of the guitar as large as
          possible. Everything was subservient to the display of the instrument
          itself. I championed the idea of visually driven navigation. This
          meant seeing the guitars themselves when making selections instead of
          clicking on model names. Just like they would at a guitar shop.
        </TextBlock>
        <Images images={["gibson-dotcom-1.png", "gibson-dotcom-2.png"]} />
        <TextBlock>
          My art direction was firmly rooted in the conviction that these
          instruments didn't need anything from me to make them appealing. What
          they needed was a digital presentation that respected their prestige.
          Focusing on my concept of a visually driven navigation lead to some
          pretty innovative interfaces which unfortunately are now lost as they
          were built in Flash.
        </TextBlock>
        <Images images={["gibson-robot-1.png", "gibson-reversev-2.png"]} />
        <TextBlock>
          In addition to overseeing some major redesigns of our corporate
          websites I also was heavily involved with the launch of new products.
          Because we lacked a CMO at the time I was called upon to play a
          central design role on the team tasked with promoting new products.
          Most every product got its own microsite which became a great place to
          test new ideas. It's where I developed our first 360 degree product
          experience which eventually made it onto standard product pages.
        </TextBlock>
        <Images images={["gibson-reversev-1.png", "gibson-reversev-2.png"]} />
        <TextBlock>
          In addition to Gibson's flagship websites I also redesigned Gibson
          Lifestyle which was an editorial site focused on music news. I built a
          custom music player, feature carousel, and video player as part of the
          design.
        </TextBlock>
        <Images images={["gibson-lifestyle-1.png", "gibson-lifestyle-2.png"]} />
        <Images images={["gibson-lifestyle-3.png", "gibson-lifestyle-4.png"]} />
      </Section>
    </div>
  );
}

export { Gibson };
